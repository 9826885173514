import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useSpring, animated } from "react-spring";

const CardStyle = {
  width: 181,
  height: 181,
  boxShadow: "18px 18px 18px 18px rgba(0,0,0,0.05)",
  backgroundColor: "white",
  borderRadius: 10,
  color: "black",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

const CardStyleMobile = {
  width: 151,
  height: 151,
  boxShadow: "18px 18px 18px 18px rgba(0,0,0,0.05)",
  backgroundColor: "white",
  borderRadius: 10,
  color: "black",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

const Image = {
  padding: "26px 0 16px 0",
  marginBottom: 0,
  borderBottom: "1px solid #EFEFEF",
  width: "100%",
  display: "flex",
  justifyContent: "center",
};

const Card = (props) => {
  const breakpoints = useBreakpoint();
  const calc = (x, y) => [
    -(y - window.innerHeight / 2) / 20,
    (x - window.innerWidth / 2) / 20,
    1.1,
  ];

  const trans = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

  const [animationProps, setAnimationProps] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 10, tension: 120, friction: 20 },
  }));

  return (
    <animated.div
      onMouseMove={({ clientX: x, clientY: y }) =>
        setAnimationProps({ xys: calc(x, y) })
      }
      onMouseLeave={() => setAnimationProps({ xys: [0, 0, 1] })}
      style={{ transform: animationProps.xys.to(trans) }}
    >
      <div style={breakpoints.sm ? CardStyleMobile : CardStyle}>
        <div style={Image}>
          <img
            src={props.img}
            style={{ width: 82, height: 65 }}
            alt="icon"
          ></img>
        </div>

        <h4 style={{ width: 105, textAlign: "center", lineHeight: 1.2 }}>
          {props.text}
        </h4>
      </div>
    </animated.div>
  );
};

export default Card;
