import React from "react";
import {useBreakpoint} from "gatsby-plugin-breakpoints";

import Button from "@mui/material/Button";
import { Link } from "gatsby";

import americaTodayLogo from "../images/america-logo.png";
import coolcatLogo from "../images/coolcat_logo.png";
import scapinoLogo from "../images/scapino-logo.png";
import ziengsLogo from "../images/ziengs_logo.png";
import msmodeLogo from "../images/msmode-logo.png";
import newblackLogo from "../images/newblack-logo.png";

const LogosSlide = () => {
    const breakpoints = useBreakpoint();
    const ClientImgStyle = {
        // width: breakpoints.sm ? 125 : 150,
        marginRight: breakpoints.sm ? 16 : 70,
    };
    return (
        <div className="logos-slide">
            <img src={scapinoLogo} style={ClientImgStyle} alt="brand icon" />
            <img src={americaTodayLogo} style={ClientImgStyle} alt="brand icon" />
            <img src={coolcatLogo} style={ClientImgStyle} alt="brand icon" />
            <img src={msmodeLogo} style={ClientImgStyle} alt="brand icon" />
            <img src={newblackLogo} style={ClientImgStyle} alt="brand icon" />
            <img src={ziengsLogo} style={ClientImgStyle} alt="brand icon" />
        </div>
    )
}

const ClientLogos = () => {
    const breakpoints = useBreakpoint();
    return (
        <div style={{ maxWidth: breakpoints.sm ? "100%" : 986, width: 986 }}>
        <div className="logos">
          <LogosSlide/>
          <LogosSlide/>
            <LogosSlide/>
        </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              color: "black",
            }}
          >
            <Button
              variant="outlined"
              color="inherit"
              style={{
                padding: "0 6 0 6",
                width: 151,
                height: 40,
                marginTop: 36,
                textTransform: "none",
              }}
            >
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/cases"
              >
                More cases
              </Link>
            </Button>
          </div>
        </div>
    )
}

export default ClientLogos;
