import "@fontsource/league-spartan";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import React, { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { animated, useSpring, useTrail } from "react-spring";
import { Waypoint } from "react-waypoint";
import Card from "../components/Card";
import Footer from "../components/Footer";
import TopNavBar from "../components/TopNavBar";
import chartImg from "../images/chart.png";
import forcastBG from "../images/forcast-background.jpg";
import forcastChart from "../images/forcast-graph.png";
import forcastChart2 from "../images/forecast-graph2.png";
import forcastChart3 from "../images/forecast-graph3.png";
import favicon from "/static/favicon.ico";
import iconAllocation from "../images/icons/icon-allocation.svg";
import iconChannel from "../images/icons/icon-channel.svg";
import iconReorder from "../images/icons/icon-reorder.svg";
import iconReplenishment from "../images/icons/icon-replenishment.svg";
import iconTransfers from "../images/icons/icon-transfers.svg";
import scrollArrow from "../images/icons/scroll-arrow.svg";
import modulesBG from "../images/modules-background.jpg";
import product1 from "../images/product1.png";
import product2 from "../images/product2.png";
import product3 from "../images/product3.png";
import Button from "@mui/material/Button";
import { Link } from "gatsby";
import ClientLogos from "../components/ClientLogos";

// Markup
const IndexPage = () => {
  const breakpoints = useBreakpoint();

  // Animations
  const fadeInSlideDown = useSpring({
    to: { opacity: 1, marginTop: 0 },
    from: { opacity: 0, marginTop: -105 },
    delay: 200,
    config: { mass: 1, tension: 100, friction: 50 },
  });

  const fadeInSlideUp = useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: 120 },
    delay: 800,
  });

  const revealConfig = { mass: 5, tension: 2000, friction: 200 };
  const aboutSubtitle = [
    "Empower your",
    "merchandisers with ",
    "data-driven decision making",
  ];

  const [toggle, set] = useState(true);

  const revealAboutSubtitle = useTrail(aboutSubtitle.length, {
    revealConfig,
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    height: toggle ? 30 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });

  const [moduleAnimationFlag, setModuleAnimationFlag] = useState(false);
  const [moduleCardsAnimation, moduleCardsAnimationHandler] = useSpring(() => ({
    opacity: 0,
    y: 80,
  }));
  const [moduleTextAnimation, moduleTextAnimationHandler] = useSpring(() => ({
    opacity: 0,
    y: 80,
  }));
  // This will retrigger when flag is changed
  useEffect(() => {
    if (moduleAnimationFlag) {
      moduleCardsAnimationHandler.start({
        opacity: 1,
        y: 0,
        delay: 600,
      });

      moduleTextAnimationHandler.start({
        opacity: 1,
        y: 0,
        delay: 300,
      });
    }
  }, [moduleAnimationFlag]);

  const [forcastTextFlag, setForcastTextFlag] = useState(false);
  const [forcastTextAnimation, forcastTextAnimationHandler] = useSpring(() => ({
    opacity: 0,
    y: 80,
  }));

  useEffect(() => {
    if (forcastTextFlag) {
      forcastTextAnimationHandler.start({
        opacity: 1,
        y: 0,
        delay: 500,
        config: { friction: 60 },
      });
    }
  }, [forcastTextFlag]);

  const [clientsSayFlag, setClientsSayFlag] = useState(false);
  const [clientsSayTitleAnimation, clientsSayTitleAnimationHandler] = useSpring(
    () => ({
      opacity: 0,
      y: 80,
    })
  );
  const [clientsSayBrandsAnimation, clientsSayBrandsAnimationHandler] =
    useSpring(() => ({
      opacity: 0,
      y: 80,
    }));

  useEffect(() => {
    if (clientsSayFlag) {
      clientsSayTitleAnimationHandler.start({
        opacity: 1,
        y: 0,
        delay: 200,
        config: { friction: 40 },
      });

      clientsSayBrandsAnimationHandler.start({
        opacity: 1,
        y: 0,
        delay: 1000,
        config: { friction: 60 },
      });
    }
  }, [clientsSayFlag]);

  // we use breakpoints to understand the current resolution being used and modify the styles
  //
  // styles
  const pageStyles = {
    color: "white",
    height: "100%",
    fontFamily: "League Spartan, Roboto, sans-serif, serif",
    zIndex: 1,
    padding: 0,
    margin: 0,
    letterSpacing: 0.66,
    overflow: "hidden",
  };

  const TopSectionStyles = {
    minHeight: 600,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const TopSectionTitle = {
    width: 350,
    textAlign: "center",
    fontSize: 34,
    fontWeight: 500,
  };

  const MidSectionTitle = {
    fontSize: 30,
    lineHeight: 1.3,
    fontWeight: 800,
  };

  const ForcastTitle = {
    fontSize: 30,
    lineHeight: 1.3,
    fontWeight: 600,
    letterSpacing: 0.78,
    marginTop: 0,
    marginBottom: 74,
    textAlign: "center",
  };

  const MidSectionWrapper = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background:
      "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 88%, rgba(0,0,0,0) 100%)",
    minHeight: breakpoints.sm ? 800 : 600,
    padding: breakpoints.sm ? "86px 16px 16px 16px" : "314px 0 0 214px",
    marginTop: breakpoints.sm ? "-120px" : "-100px",
  };

  const videoWrapper = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: "-1",
    pointerEvents: "none",
    overflow: "hidden",
  };

  const videoStyle = {
    width: "100vw",
    height: "56.25vw" /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */,
    minHeight: "100vh",
    minWidth: "177.77vh" /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const videoDarkBg = {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    left: 0,
    top: breakpoints.sm ? 0 : -200,
    zIndex: -1,
  };

  const MidSectionInnerWrapper = {
    display: "flex",
    flexDirection: breakpoints.sm ? "column" : "row",
    justifyContent: "center",
    marginTop: breakpoints.sm ? 56 : 0,
    maxWidth: breakpoints.sm ? "100%" : 986,
    width: breakpoints.sm ? "100%" : 986,
  };

  const ModulesWrapper = {
    backgroundImage: `url(${modulesBG})`,
    backgroundSize: "cover",
    height: breakpoints.sm ? 850 : 600,
    backgroundRepeat: "repeat-y",
    position: "relative",
    padding: breakpoints.sm ? "86px 16px 16px 16px" : " 214px 64px 0 64px",
    transform: "skewY(-6deg)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const ModulesInnerWrapper = {
    maxWidth: breakpoints.sm ? "100%" : 986,
  };

  const ModulesLeftSide = {
    display: "grid",
    gridTemplate: "1fr 1fr 1fr 1fr / 1fr 1fr",
    gridGap: breakpoints.sm ? 24 : 38,
    width: breakpoints.sm ? "100%" : 543,
    height: 543,
    marginTop: breakpoints.sm ? "-60px" : "-255px",
    marginLeft: breakpoints.sm ? 0 : "-300px",
    transform: "skewY(6deg)",
    marginRight: "auto",
  };

  const ModulesRightSide = {
    color: "black",
    width: breakpoints.sm ? "100%" : 485,
    position: breakpoints.sm ? "relative" : "absolute",
    left: breakpoints.sm ? 0 : "55%",
    transform: "skewY(6deg)",
    marginLeft: breakpoints.sm ? 16 : 66,
  };

  const ForcastWrapper = {
    backgroundImage: `url(${forcastBG})`,
    backgroundMinSize: 1940,
    backgroundSize: "cover",
    backgroundRepeat: "repeat-y",
    position: "relative",
    padding: breakpoints.sm ? "86px 16px 16px 16px" : "114px 0 0 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: breakpoints.sm ? 700 : 800,
    transform: "skewY(-6deg)",
    transformOrigin: `bottom left`,
  };

  const TshirtImage = {
    width: breakpoints.sm ? 159 : 312,
    height: breakpoints.sm ? 95 : 173,
    position: "absolute",
    top: breakpoints.sm ? -12 : -15,
    right: breakpoints.sm ? -7 : -5,
    bottom: breakpoints.sm ? 0 : "none",
  };

  const ClientsSay = {
    minHeight: 700,
    padding: breakpoints.sm ? "86px 16px 16px 16px" : "114px 0 0 64px",
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const ClientsTitle = {
    fontSize: breakpoints.sm ? 20 : 30,
    width: breakpoints.sm ? "100%" : "100%",
    textAlign: "center",
    color: "black",
  };


  const scrollBtnRef = useRef();

  const handleBackClick = () => {
    scrollBtnRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <main style={pageStyles}>
      <Helmet>
        <title>Thunderstock</title>
        <link rel="canonical" href="http://thunderstock.nl" />
      </Helmet>

      <TopNavBar />

      <div>
        <div style={videoWrapper}>
          <iframe
            title="bg video"
            style={videoStyle}
            src={
              "https://player.vimeo.com/video/582590681?h=ece3d51e11?background=1&muted=1&autoplay=1&dnt=1&loop=1&controls=0"
            }
            allow="autoplay; encrypted-media; picture-in-picture; loop"
            frameBorder="0"
          />
        </div>

        <div style={videoDarkBg}></div>

        <div style={TopSectionStyles}>
          <animated.div style={fadeInSlideDown}>
            <h2 style={TopSectionTitle}>
              AI powered SaaS platform for retail stock optimization
            </h2>
          </animated.div>

          <div
            style={
              breakpoints.sm
                ? {
                    width: "100%",
                    position: "absolute",
                    top: "85%",
                    left: "0",
                    right: "0",
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }
                : {
                    width: "100%",
                    position: "absolute",
                    top: "100%",
                    left: "50%",
                    right: "50%",
                  }
            }
          >
            <animated.div style={fadeInSlideUp}>
              <img src={scrollArrow} onClick={handleBackClick} alt="arrow" />
            </animated.div>
          </div>
        </div>

        <div style={MidSectionWrapper} ref={scrollBtnRef}>
          <div style={MidSectionInnerWrapper}>
            <div style={{ width: "100%", marginLeft: 0 }}>
              <h3 style={{ color: "#FF9800", fontSize: 15 }}>
                About Thunderstock
              </h3>

              <div style={{ height: 150, width: 300 }}>
                {revealAboutSubtitle.map(({ x, height, ...rest }, index) => (
                  <animated.div
                    key={aboutSubtitle[index]}
                    style={{
                      ...rest,
                      position: "relative",
                      width: "100%",
                      height: "35px",
                      transform: x.interpolate(
                        (x) => `translate3d(0,${x}px,0)`
                      ),
                    }}
                  >
                    <animated.div style={{ height }}>
                      <span style={MidSectionTitle}>
                        {aboutSubtitle[index]}
                      </span>
                    </animated.div>
                  </animated.div>
                ))}
              </div>

              <animated.div style={fadeInSlideUp}>
                <p style={{ marginTop: 26 }}>
                  Thunderstock empowers intelligent and agile stock decisions,
                  allowing merchandisers to focus on macro management and exceptions,
                  instead of manual routines.
                  Visually engaging interfaces allow merchandisers to easily grasp patterns in complex data.
                </p>
              </animated.div>
            </div>

            <img
              alt="chart"
              src={chartImg}
              style={
                breakpoints.sm
                  ? { width: 664, height: 236, marginTop: 36 }
                  : {
                      height: 276,
                      marginLeft: 32,
                      maxWidth: 800,
                      width: 800,
                      marginTop: 60,
                    }
              }
            />
          </div>
        </div>

        <div style={ModulesWrapper}>
          <Waypoint onEnter={() => setModuleAnimationFlag(true)} />

          <div style={ModulesInnerWrapper}>
            <animated.div
              style={{ ...moduleCardsAnimation, ...ModulesLeftSide }}
            >
              <span style={{ gridRow: "1/2", gridColumn: "1/2" }}>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/modules-page"
                >
                  <Card img={iconAllocation} text="Allocation" />
                </Link>
              </span>
              <span style={{ gridRow: "2/3", gridColumn: "1/2" }}>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/modules-page"
                >
                  <Card img={iconChannel} text="Channel Management" />
                </Link>
              </span>
              <span style={{ gridRow: "3/4", gridColumn: "1/2" }}>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/modules-page"
                >
                  <Card img={iconReorder} text="Reorder" />
                </Link>
              </span>
              <span
                style={{
                  gridRow: "2/3",
                  gridColumn: "2/3",
                  marginTop: "-90px",
                }}
              >
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/modules-page"
                >
                  <Card img={iconReplenishment} text="Replenishment" />
                </Link>
              </span>
              <span
                style={{
                  gridRow: "3/4",
                  gridColumn: "2/3",
                  marginTop: "-90px",
                }}
              >
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/modules-page"
                >
                  <Card img={iconTransfers} text="Transfers" />
                </Link>
              </span>
            </animated.div>
          </div>

          <div style={ModulesRightSide}>
            <animated.div style={moduleTextAnimation}>
              <h3 style={{ color: "#FF9800", fontSize: 15 }}>Our Modules</h3>
              <h2 style={MidSectionTitle}>
                Each of our modules aids the merchandising process at different
                stages of the product life cycle
              </h2>
              <Button
                variant="outlined"
                color="inherit"
                style={{
                  padding: "0 6 0 6",
                  width: 151,
                  height: 40,
                  marginTop: 16,
                  textTransform: "none",
                }}
              >
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/modules-page"
                >
                  Learn more
                </Link>
              </Button>
            </animated.div>
          </div>
        </div>

        <div style={ForcastWrapper}>
          <div style={{ transform: "skewY(6deg)" }}>
            <h3
              style={{
                color: "#FF9800",
                fontSize: 15,
                textAlign: "center",
                width: "100%",
              }}
            >
              Forecasts
            </h3>
            <h2 style={ForcastTitle}>Let AI help you do the work</h2>

            <div
              style={{
                position: "relative",
                maxWidth: breakpoints.sm ? "100%" : 986,
              }}
            >
              <Carousel
                autoPlay="true"
                infiniteLoop="true"
                ariaLabel={false}
                showIndicators={false}
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                transitionTime="1000"
                interval="6000"
                labels={false}
                renderIndicator={false}
                emulateTouch="true"
              >
                <div>
                  <img
                    alt="forecast chart"
                    src={forcastChart}
                    style={
                      breakpoints.sm
                        ? { width: "100%", height: 197 }
                        : { width: "100%", height: "100%" }
                    }
                  />
                  <img src={product1} style={TshirtImage} alt="product" />
                </div>

                <div>
                  <img
                    alt="forecast chart"
                    src={forcastChart2}
                    style={
                      breakpoints.sm
                        ? { width: "100%", height: 197 }
                        : { width: "100%", height: "100%" }
                    }
                  />
                  <img src={product2} style={TshirtImage} alt="product" />
                </div>

                <div>
                  <img
                    alt="forecast chart"
                    src={forcastChart3}
                    style={
                      breakpoints.sm
                        ? { width: "100%", height: 197 }
                        : { width: "100%", height: "100%" }
                    }
                  />
                  <img alt="product" src={product3} style={TshirtImage} />
                </div>
              </Carousel>
            </div>

            <Waypoint onEnter={() => setForcastTextFlag(true)} />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <animated.div style={forcastTextAnimation}>
                <p
                  style={
                    breakpoints.sm
                      ? { width: "100%", textAlign: "center", marginTop: 36 }
                      : { width: 635, textAlign: "center" }
                  }
                >
                  Stock optimizations rely on our proprietary, AI-based
                  forecasting engine, generating superior demand forecasts. This
                  allows us to accurately tailor stock levels to customer demand
                  and avoid missed sales with just enough stock.
                </p>
              </animated.div>
              <animated.div style={forcastTextAnimation}>
                <Button
                  variant="outlined"
                  color="inherit"
                  style={{
                    padding: "0 6 0 6",
                    width: 151,
                    height: 40,
                    marginTop: 16,
                    textTransform: "none",
                  }}
                >
                  <Link
                    style={{ textDecoration: "none", color: "inherit" }}
                    to="/forecasts"
                  >
                    Learn more
                  </Link>
                </Button>
              </animated.div>
            </div>
          </div>
        </div>

        <div style={ClientsSay}>
          <Waypoint onEnter={() => setClientsSayFlag(true)} />
          <div style={{ maxWidth: breakpoints.sm ? "100%" : 986, width: 986 }}>
            <animated.div style={clientsSayTitleAnimation}>
              <h3
                style={{
                  color: "#FF9800",
                  fontSize: 15,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                What our clients say
              </h3>

              <Carousel
                autoPlay="true"
                infiniteLoop="true"
                showIndicators={false}
                showArrows={false}
                showThumbs={false}
                transitionTime="1000"
                interval="12000"
                labels={false}
                emulateTouch="true"
                showStatus={false}
              >
                <div>
                  <h2 style={ClientsTitle}>
                    “We use Thunderstock to optimize our allocation &
                    replenishment. The tool allowed us to decrease our missed
                    sales from 4% to 2% within the first months.”
                  </h2>
                  <h4
                    style={{
                      color: "black",
                      fontSize: 14,
                      marginTop: "-5px",
                      textAlign: "center",
                    }}
                  >
                    Linda from <b>America Today</b>
                  </h4>
                </div>
                <div>
                  <h2 style={ClientsTitle}>
                    “Through better use of historical and current data in
                    Thunderstock, we realize an optimal allocation for all
                    channels (brick stores and online shops). But it also let's
                    us replenish based on sales potential, this minimizes missed
                    sales and improves margin”
                  </h2>
                  <h4
                    style={{
                      color: "black",
                      fontSize: 14,
                      marginTop: "-5px",
                      textAlign: "center",
                    }}
                  >
                    Dennis Mok from <b>MS Mode</b>
                  </h4>
                </div>
              </Carousel>
            </animated.div>
          </div>
          <ClientLogos/>
        </div>
        <Footer />
      </div>
    </main>
  );
};

export default IndexPage;
